import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const EventsPage = () => (
  <Layout>
    <SEO title="Events" />
    <section className="intro">
      <div className="intro-container">
        <h2>Events</h2>
        <p>Currently, due to the coronavirus pandemic, the Asian Leather and Fetish Alliance is not holding in-person events.</p>
      </div>
    </section>
    <section className="content">
      <div className="content-container">
        <p>Check back soon for more information.</p>
        <p><Link to="/" className="call-to-action">Go back home</Link></p>
      </div>
    </section>
  </Layout>
)

export default EventsPage
